import { useQuery } from '@tanstack/react-query';
import { useMobile } from 'common-nextjs';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { fetchSortConfig } from '~/api/configuration';
import { fetchLockerItemsNoCache } from '~/api/locker';
import ManagedItemThumbnailGrid from '~/components/ItemGrids/ManagedItemThumbnailGrid';
import HorizontalItemThumbnail from '~/components/ItemThumbnail/HorizontalItemThumbnail';
import LockerItemTable from '~/components/ItemThumbnail/ItemTable/LockerItemTable';
import FloatingBulkActionBar from '~/components/NewLockers/BulkActions/FloatingBulkActionBar';
import LockerEmptyState from '~/components/NewLockers/LockerEmptyState';
import LockerSearch from '~/components/NewLockers/LockerSearch';
import NumberedPaginator from '~/components/Pagination/NumberedPaginator';
import Paginator from '~/components/Pagination/Paginator';
import { FacetFilteringProvider } from '~/contexts/facetFiltering/FacetFilteringContext';
import flattenFilterState from '~/contexts/facetFiltering/flattenFilterState';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import useBrowserRouteQuery from '~/hooks/useBrowserRouteQuery';
import useItemGridMeta from '~/hooks/useItemGridMeta';
import { LockerItemPageQuery } from '~/typings/pages/lockers';
import { RailsThumbnailItem } from '~/typings/services/rails/item';
import { LockerRouteQuery } from '../../pages/locker/[username]/[itemState]';

interface Props {}

const LockerItemPageContent: React.FC<Props> = () => {
  const myLocker = useIsMyLocker();
  const query = useBrowserRouteQuery<LockerItemPageQuery>();
  const mobile = useMobile();
  const router = useRouter();
  const {
    itemState,
    username,
    select: isSelectingItems,
  } = router.query as LockerRouteQuery;

  // If the user decides to select ALL items
  const [isSelectingAll, setIsSelectingAll] = useState(false);
  // list of item ids
  const [selectedItemsList, setSelectedItemsList] = useState<number[]>([]);

  function toggleSelectedItem(item: RailsThumbnailItem) {
    const itemId = item.id;

    setIsSelectingAll(false);
    if (selectedItemsList.includes(itemId)) {
      setSelectedItemsList(selectedItemsList.filter(id => id !== itemId));
    } else {
      setSelectedItemsList([...selectedItemsList, itemId]);
    }
  }

  function toggleSelectAll() {
    setIsSelectingAll(!isSelectingAll);
  }

  useEffect(() => {
    // If the user changes pages, we need to clear the selected items
    setSelectedItemsList([]);
  }, [router.asPath]);

  const {
    data: facetResponse,
    refetch,
    isLoading,
  } = useQuery(
    ['lockerItems', router.query, myLocker],
    () =>
      fetchLockerItemsNoCache({
        ...query,
        q: query.q ? query.q + '*' : undefined,
        context: myLocker ? 'locker_owner' : 'locker_visitor',
        itemState,
        username,
      }),
    {
      refetchOnMount: false,
      staleTime: 30000,
    },
  );
  useItemGridMeta(
    facetResponse?.data,
    { seller: [username], src: 'locker' },
    myLocker ? 'locker' : 'results',
  );

  const { data: sortOptions } = useQuery(
    ['locker-sorts'],
    () => fetchSortConfig(myLocker ? 'locker_owner' : 'locker_visitor'),
    {
      staleTime: Infinity,
    },
  );

  let itemsContent: React.ReactNode = null;
  if (myLocker) {
    if (mobile) {
      itemsContent = (
        <div className="divide-y">
          {facetResponse?.data.map(item => (
            <HorizontalItemThumbnail
              className="pb-2 pt-4"
              key={item.id}
              onInvalidate={refetch}
              item={item}
              isSelectingAll={isSelectingAll}
              selected={selectedItemsList.includes(item.id)}
              selecting={!!isSelectingItems}
              onSelect={toggleSelectedItem}
            />
          ))}
        </div>
      );
    } else if (facetResponse) {
      itemsContent = (
        <LockerItemTable
          items={facetResponse?.data}
          onInvalidate={refetch}
          isSelectingAll={isSelectingAll}
          selecting={!!isSelectingItems}
          selectedItems={selectedItemsList}
          onSelect={toggleSelectedItem}
        />
      );
    }
  } else if (facetResponse) {
    itemsContent = (
      <ManagedItemThumbnailGrid
        items={facetResponse.data}
        onInvalidate={refetch}
      />
    );
  }

  return (
    <>
      <FacetFilteringProvider
        facets={facetResponse?.meta.facets || []}
        filterState={flattenFilterState(facetResponse?.meta.query)}
        paging={facetResponse?.meta.paging}
        sorts={sortOptions}
        type="locker"
      >
        <LockerSearch
          facetResponse={facetResponse}
          isSelectingAll={isSelectingAll}
          onToggleSelectAll={toggleSelectAll}
        />

        {!facetResponse || facetResponse.data.length === 0 ? (
          <LockerEmptyState />
        ) : (
          <>
            {itemsContent}

            {facetResponse?.meta.paging &&
              (mobile ? (
                <Paginator
                  paging={facetResponse.meta.paging}
                  className="justify-center"
                />
              ) : (
                <NumberedPaginator paging={facetResponse.meta.paging} />
              ))}
          </>
        )}

        {isSelectingItems && (
          <FloatingBulkActionBar
            selectedItems={selectedItemsList}
            isSelectingAll={isSelectingAll}
            totalItems={facetResponse?.meta.paging?.total_count || 0}
          />
        )}
      </FacetFilteringProvider>
    </>
  );
};

export default LockerItemPageContent;
