import React, { useState } from 'react';
import { Button, NumberInput } from 'ui';
import { pluralize, thousandsSeparator } from 'utils';
import NewModalCloseActionButton from '~/components/NewModal/NewModalCloseActionButton';
import SimpleModal from '~/components/NewModal/SimpleModal';

interface Props {
  icon: React.ReactNode;
  label: React.ReactNode;
  mutate: () => void;
  count: number;
}

const BulkActionRemoveConfirmationModal: React.FC<Props> = ({
  count,
  icon,
  label,
  mutate,
}) => {
  const [open, setOpen] = useState(false);
  const [typedCount, setTypedCount] = useState<number | ''>('');

  const handleClickRemove = () => {
    mutate();
    setOpen(false);
  };

  return (
    <>
      <button
        className="flex flex-col items-center justify-center space-y-1"
        onClick={() => setOpen(true)}
      >
        {icon}
        <span className="text-xs font-semibold">{label}</span>
      </button>

      <SimpleModal
        open={open}
        onClose={() => setOpen(false)}
        title="Bulk Remove Items"
      >
        <p className="mb-4">
          You&apos;re about to remove <b>{thousandsSeparator(count)}</b>{' '}
          {pluralize(count, 'item ', 'items ')}
          from your locker.
        </p>

        <p className="mb-2 font-semibold">
          Type the number of items you&apos;re removing to confirm:
        </p>

        <NumberInput
          className="w-full"
          onValueChange={value => setTypedCount(value.floatValue || '')}
          value={typedCount}
        />

        <div className="mt-6 grid grid-cols-2 gap-2">
          <NewModalCloseActionButton>Cancel</NewModalCloseActionButton>

          <Button
            color="danger"
            onClick={handleClickRemove}
            disabled={typedCount !== count}
          >
            Remove
          </Button>
        </div>
      </SimpleModal>
    </>
  );
};

export default BulkActionRemoveConfirmationModal;
