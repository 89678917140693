'use client';

import { withQueryClient } from 'common-nextjs';
import { getSession, getSessionUsername } from 'fetcher-session';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useMount } from 'react-use';
import { prefetchLockerItems } from '~/api/locker';
import SportsEngineSoftLanding from '~/components/ItemDetails/SportsEngineSoftLanding';
import Layout from '~/components/Layout';
import LockerItemStates from '~/components/NewLockers/Header/LockerItemStates';
import LockerCoachingModePage from '~/components/NewLockers/LockerCoachingModePage';
import LockerItemPageContent from '~/components/NewLockers/LockerItemPageContent';
import LockerMeta from '~/components/NewLockers/LockerMeta';
import LockerPageContainer from '~/components/NewLockers/LockerPageContainer';
import { useModals } from '~/contexts/ModalsContext';
import useIsMyLocker from '~/hooks/lockers/useIsMyLocker';
import { prefetchLockerUser } from '~/hooks/lockers/useLockerUser';
import { prefetchPublicProfile } from '~/hooks/lockers/usePublicProfile';
import useBrowserRouteQuery from '~/hooks/useBrowserRouteQuery';
import { prefetchPageViewById } from '~/hooks/usePageView';
import { visitedLocker } from '~/services/analytics/events/generic';
import { CustomNextPage } from '~/typings/custom-next';
import { LockerItemPageQuery } from '~/typings/pages/lockers';
import { RailsItem } from '~/typings/services/rails/item';
import {
  bigCatLockerBottomPageViewId,
  bigCatLockerTopPageViewId,
} from '../../../constants/bigCatHardcodedLockerPageViewIds';
import bigCatUserId from '../../../constants/bigCatUserId';

export interface LockerRouteQuery extends ParsedUrlQuery {
  username: string;
  itemState?: RailsItem['state'];
  select?: string;
  coaching?: string; // coaching mode enabled, undefined or "1"
  coaching_index?: string; // coaching mode, value is the ID of the item you're coaching
}

interface Props {}

const NewLockerItemPage: CustomNextPage<Props> = () => {
  const myLocker = useIsMyLocker();
  const query = useBrowserRouteQuery<LockerItemPageQuery>();
  const modals = useModals();
  const router = useRouter();
  const { itemState, username, coaching } = router.query as LockerRouteQuery;
  const isCoachingMode = coaching != null;

  useMount(() => {
    if (query.sale) {
      modals.openModal('saleGuidance', {});
      router.push('/locker/[username]', `/locker/${username}`, {
        shallow: true,
      });
    }

    visitedLocker(itemState || 'available', myLocker);
  });

  return (
    <Layout
      title={router.query.username as string}
      excludeMobileSearch
      container={false}
    >
      <LockerMeta />
      <SportsEngineSoftLanding />
      <LockerPageContainer>
        {isCoachingMode ? null : <LockerItemStates />}
        {isCoachingMode ? (
          <LockerCoachingModePage />
        ) : (
          <LockerItemPageContent />
        )}
      </LockerPageContainer>
    </Layout>
  );
};

NewLockerItemPage.getInitialProps = withQueryClient(
  queryClient => async ctx => {
    const session = await getSession(ctx);

    const query = ctx.query as LockerRouteQuery;
    const [user] = await Promise.all([
      prefetchLockerUser(query.username, queryClient, ctx),
      prefetchPublicProfile(query.username, queryClient),
      prefetchLockerItems(
        ctx,
        query,
        queryClient,
        query.username === getSessionUsername(session),
      ),
    ] as const);

    if (user.id === bigCatUserId) {
      await Promise.all([
        prefetchPageViewById(bigCatLockerBottomPageViewId, queryClient, ctx),
        prefetchPageViewById(bigCatLockerTopPageViewId, queryClient, ctx),
      ]);
    }

    return {};
  },
);

export default NewLockerItemPage;
