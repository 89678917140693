'use client';

import React, { useState } from 'react';
import ConfirmationModal, {
  ConfirmationModalProps,
} from '~/components/NewModal/ConfirmationModal';

type TriggerEl = React.ReactElement<
  { onClick?: React.MouseEventHandler },
  'button'
>;

export default function ManagedConfirmationModal({
  trigger,
  ...props
}: {
  trigger: TriggerEl;
} & Omit<ConfirmationModalProps, 'open' | 'onClose'>) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const Trigger = React.Children.map(trigger, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClick: e => {
          child.props?.onClick?.(e);
          setOpen(true);
        },
      });
    }
  });

  return (
    <>
      {Trigger}

      <ConfirmationModal
        {...props}
        handleOK={async () => {
          try {
            const promise = props.handleOK?.();
            if (promise != null && 'then' in promise) {
              setLoading(true);
            }
            await promise;
            setOpen(false);
          } catch (e) {
            throw e;
          } finally {
            setLoading(false);
          }
        }}
        loading={loading}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
